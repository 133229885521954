<template>
  <login />
</template>

<script>
  import Login from '../components/Login'
// import Login from '../components/Login.vue'

  export default {
    name: 'Home',

    components: {
      Login
    },
  }
</script>
