import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../components/Login.vue')
  },
  {
    path: '/Olvidacontra',
    name: 'Olvidacontra',
    component: () => import(/* webpackChunkName: "Olvidacontra" */ '../components/Olvidacontra.vue')
  },
  {
    path: '/Movil/',
    name: 'Movil',
    component: () => import(/* webpackChunkName: "Movil" */ '../components/Movil.vue')
  },
  {
    path: '/Pagos',
    name: 'Pagos',
    component: () => import(/* webpackChunkName: "Pagos" */ '../components/Pagos.vue')
  },
  {
    path: '/Perfil',
    name: 'Perfil',
    component: () => import(/* webpackChunkName: "Perfil" */ '../components/Perfil.vue')
  },
  {
    path: '/Portar',
    name: 'Portar',
    component: () => import(/* webpackChunkName: "Portar" */ '../components/Portar.vue')
  },
  {
    path: '/Recargas',
    name: 'Recargas',
    component: () => import(/* webpackChunkName: "Recargas" */ '../components/Recargas.vue')
  },
  {
    path: '/VerificaCodigo/:email',
    name: 'VerificaCodigo',
    component: () => import(/* webpackChunkName: "VerificaCodigo" */ '../components/VerificaCodigo.vue')
  },
  {
    path: '/Recargas2/:numero/:plan/:metodo',
    name: 'Recargas2',
    component: () => import(/* webpackChunkName: "Recargas2" */ '../components/Recargas2.vue')
  },
  {
    path: '/PagoTarjeta/:numero/:plan/:metodo?',
    name: 'PagoTarjeta',
    component: () => import(/* webpackChunkName: "Pagos" */ '../components/PagoTarjeta.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
