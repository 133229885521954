<template>
  <v-app id="inspire">
    <!-- BARRA SUPERIOR -->
    <v-app-bar
      app
      color="primary"
      dark
    >

    <v-tabs fixed-tabs v-if="!muestra">
      <v-tab to="/Movil">Movil</v-tab>
      <v-tab to="/Pagos">Pagos</v-tab>
      <v-tab to="/Perfil">Perfil</v-tab>
    </v-tabs>

    </v-app-bar>



    <v-main >
      <v-card class="elevation-3 " height="100%">
        <v-container fluid class="mx-0">
          <v-slide-y-transition mode="out-in">
            <router-view @muestra="muestra = $event" />
          </v-slide-y-transition>
        </v-container>
      </v-card>
    </v-main>

  </v-app>
</template>

<script>
  export default {
    data: () => ({
      muestra: true
    }),

    created () {
    },  

    methods:{
      showTitle(){
        return !(this.$route.name === 'Home' || this.$route.name === 'AgregarUsrs' || this.$route.name === 'Login')
      }

    }
  };
</script>

